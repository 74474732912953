import React, { useEffect } from "react";
import CustomSlider from "./CustomSlider2";

function PrettoSlider2() {
  
  const ls_value = localStorage.getItem("estudios");

  let horas = "00";
  let minutos = "00";

  if (ls_value !== null) {
    const tiempo = ls_value.toString().split(".");
    
    horas = tiempo[0] === "0" ? "00" : tiempo[0];
    minutos = tiempo[1] === "5" ? 30 : "00";

  }

  const [value, setValue] = React.useState(ls_value !== null ? parseFloat(ls_value) : 0);

  useEffect(() => {
    localStorage.setItem("estudios", value);
  }, [value]);

  return <CustomSlider label="Slider_2" value={value} setValue={setValue} horas={horas} minutos={minutos} />;
}

export default PrettoSlider2;
