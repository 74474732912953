import React, { useEffect, useState } from "react";
import "../../App.css";
import "./sobre_ti.scss";
import SideBar from "../../components/NavBar/SideBar";
import { Link, useHistory } from "react-router-dom";

import PrettoSliderEdad from "../../components/PrettoSliderEdad/PrettoSliderEdad";
import InputTextGenero from "../../components/PreguntasPersonales/InputTextGenero";
import InputTextCodPost from "../../components/PreguntasPersonales/InputTextCodPost";
import InputTextEmail from "../../components/PreguntasPersonales/InputTextEmail";

import ModalPrivacidad from '../../components/Modal/ModalPrivacidad';
import UseModal from '../../components/Modal/UseModal';

import axios from "axios";

import chevron_down from "../../media/chevron-arrow-down.svg";

function SobreTi() {

  const ls_value_proteccion = localStorage.getItem("proteccion");
  const ls_value_info = localStorage.getItem("info");

  const { isShowing, toggle } = UseModal();
  const [checkProteccion, setCheckProteccion] = useState(ls_value_proteccion == null ? false : (ls_value_proteccion !== 'true' ? false : true));
  const [checkInfo, setCheckInfo] = useState(ls_value_info == null ? false : (ls_value_info !== 'true' ? false : true));

  useEffect(() => {

    const itemFocus = document.getElementById("menu-2");
    itemFocus.classList.add("link-activo");

    localStorage.setItem("proteccion", checkProteccion);
    localStorage.setItem("info", checkInfo);

  });

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  let history = useHistory();

  function handleEncuestaSubmit(e) {
    e.preventDefault();

    const trabajo = parseFloat(localStorage.getItem("trabajo"));
    const estudios = parseFloat(localStorage.getItem("estudios"));
    const alimentacion = parseFloat(localStorage.getItem("alimentacion"));
    const hogar = parseFloat(localStorage.getItem("hogar"));
    const cuidados = parseFloat(localStorage.getItem("cuidados"));
    const gestiones = parseFloat(localStorage.getItem("gestiones"));
    const autocuidado = parseFloat(localStorage.getItem("autocuidado"));
    const ocio = parseFloat(localStorage.getItem("ocio"));
    const activismo = parseFloat(localStorage.getItem("activismo"));

    const edad_ls = parseInt(localStorage.getItem("edad"));
    const edad = isNaN(edad_ls) === true ? 'null' : edad_ls;

    const genero = localStorage.getItem("genero");
    const cod_postal = localStorage.getItem("cod_postal");
    const email = localStorage.getItem("email");

    const proteccion = localStorage.getItem("proteccion");
    const info = localStorage.getItem("info");

    const total_horas =
      trabajo +
      estudios +
      alimentacion +
      hogar +
      cuidados +
      gestiones +
      autocuidado +
      ocio +
      activismo;

    if (total_horas === 0) {
      alert(
        "Todos los valores de la encuesta son cero. Por favor indique sus cifras."
      );
      return false;
    } else if (genero === '') {
      alert(
        "Por favor indica tu género; si no quieres puedes escribir \"no quiero\"."
      );
      return false;
    } else if (cod_postal === '') {
      alert(
        "Por favor indica tu código postal; si no lo sabes indica tu barrio/ciudad."
      );
      return false;
    } else if (proteccion === "false") {
      alert(
        "Por favor marca la casilla de \"He leído y acepto la política de protección de datos\"."
      );
      return false;
    } else if (info === "false") {
      if (email !== '') {
        alert(
          "Has introducido tu cuenta de correo electrónico pero no has marcado la casilla de querer recibir información. Si es así, borra el correo electrónico introducido y si quieres recibir información marca la casilla ;-)"
        );
        return false;
      }
    } else if (info === "true") {
      if (email === '') {
        alert(
          "Has marcado la casilla de querer recibir información pero no has introducido tu cuenta de correo electrónico. Si es así, desmarca la casilla y si quieres recibir información escribe tu correo electrónico ;-)"
        );
        return false;
      }
    }

    if (info === "true" && email !== "") {
      if (!validateEmail(email)) {
        alert(
          "Por favor, introduce una dirección de corre electrónico válida para que puedas recibir información."
        );
        return false;
      }
    }

    window.localStorage.removeItem("id_registro");

    axios({
      method: "post",
      baseURL: "https://encuestatiempo.ecosfron.org/",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      url: "services/dao_encuesta.php",
      data: {
        trabajo: trabajo,
        estudios: estudios,
        alimentacion: alimentacion,
        hogar: hogar,
        cuidados: cuidados,
        gestiones: gestiones,
        autocuidado: autocuidado,
        ocio: ocio,
        activismo: activismo,
        genero: genero,
        edad: edad,
        cod_postal: cod_postal,
        email: email,
      },
    }).then(function (response) {
      window.localStorage.setItem("id_registro", response.data.id_registro);
      history.push("/resultados");
    });
  }

  return (
    <React.Fragment>
      <div id="page-sobre-ti">
        <header id="app-header" className="app-header-verde">
          <SideBar
            pageWrapId={"page-wrap"}
            outerContainerId={"page-sobre-ti"}
          />
          <h1 id="header-title" className="header-title">
            {"Algunos datos sobre ti"}
          </h1>
        </header>

        <main className="app-main">

          <div id="app-main-container" className="app-main-container">

            <div
              id="preguntas-personales-box"
              className="preguntas-personales-box"
            >
              <InputTextGenero />

              <InputTextCodPost />

              <PrettoSliderEdad />

              <div className="encuesta-text-box checkbox">
                <input
                  type="checkbox"
                  id="proteccion"
                  name="proteccion"
                  checked={checkProteccion}
                  onChange={(e) => setCheckProteccion(e.target.checked)}
                />
                <label className="label-check" htmlFor="proteccion">{" "}He leído y acepto la{" "}<Link target="_blank" to="/aviso_legal" >política de protección de datos</Link>.
                </label>

              </div>

              <div className="encuesta-text-box checkbox last-checkbox">

                <ModalPrivacidad
                  isShowing={isShowing}
                  hide={toggle}
                />
                <input
                  type="checkbox"
                  id="info"
                  name="info"
                  checked={checkInfo}
                  onChange={(e) => setCheckInfo(e.target.checked)}
                />
                <label className="label-check" htmlFor="info">
                  {" "}
                  Acepto mantenerme informado sobre las actividades formativas que organizamos desde Economistas sin Fronteras. En cualquier momento puedes darte de baja. Puedes consultar las condiciones en nuestra{" "}
                  <button className="btn-modal-privacy" onClick={toggle}>política de privacidad</button>
                  .
                </label>

              </div>

              <InputTextEmail />

              <div className="btn-encuesta-box">
                <button
                  className="encuesta-button"
                  onClick={handleEncuestaSubmit}
                >
                  <div className="div-button-resultados icon-encuesta">
                    <span className="span-next">calcular resultados</span>
                    <img src={chevron_down} alt="icono de ir a resultados" />
                  </div>
                </button>
              </div>

              <Link to="/encuesta" className="encuesta-link btn">
                <div className="div-btn-encuesta volver">
                  <img src={chevron_down} alt="icono de ir a encuesta" />
                  <span className="span-goback">volver a encuesta</span>
                </div>
              </Link>

            </div>
          </div>
        </main>
        <footer className="footer-sobre-ti">
					<p>CC Economistas sin Fronteras 2021 | <Link target="_blank" to="/aviso_legal">Aviso Legal</Link></p>
				</footer>
      </div>
    </React.Fragment>
  );
}

export default SobreTi;
