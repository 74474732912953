import React, { useEffect } from "react";
import CustomSlider from "./CustomSliderEdad";

function PrettoSliderEdad() {

  const ls_value = localStorage.getItem("edad");

  const [value, setValue] = React.useState(ls_value === null ? null : parseInt(ls_value));

  useEffect(() => {
    localStorage.setItem("edad", value);
  }, [value]);

  return <CustomSlider label="Slider_edad" value={value} setValue={setValue} />;
}

export default PrettoSliderEdad;
