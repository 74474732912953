import React from "react";

const CustomInputTextEmail = ({ value, setValue }) => {

  return (

    <div className="encuesta-text-box">
      <label htmlFor="email-input" className="encuesta-label hidden">
        Deja tu correo si:
      </label>
      <input
        name="email-input"
        type="text"
        placeholder="hola@tucorreo.com"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        value={value}
        onChange={event => {
          event.preventDefault();
          setValue(event.target.value);
        }}

      />
    </div>

  );
};
export default CustomInputTextEmail;