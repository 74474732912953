import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Inicio from './pages/inicio/inicio';
import Encuesta from './pages/encuesta/encuesta';
import SobreTi from './pages/encuesta/sobre_ti';
import Resultados from './pages/resultados/resultados';
import MasInfo from './pages/masinfo/masinfo';
import About from './pages/about/about';
import AvisoLegal from './pages/avisolegal/avisolegal';

import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div id="App" className="App">
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Inicio} />
          <Route path="/encuesta" component={Encuesta} />
          <Route path="/sobre_ti" component={SobreTi} />
          <Route path="/resultados" component={Resultados} />
          <Route path="/mas_informacion" component={MasInfo} />
          <Route path="/sobre_esf" component={About} />
          <Route path="/aviso_legal" component={AvisoLegal} />
        </Switch>
    </div>
  );
}

export default App;