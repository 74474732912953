import React, { useEffect } from "react";
import CustomInputTextCodPostal from "./CustomInputTextCodPostal";

function InputTextCodPostal() {
  
  const ls_value = localStorage.getItem("cod_postal");

  const [value, setValue] = React.useState(ls_value == null ? '' : ls_value);

  useEffect(() => {
    localStorage.setItem("cod_postal", value);
  }, [value]);

  return <CustomInputTextCodPostal label="Input_cod_postal" value={value} setValue={setValue} />;
}

export default InputTextCodPostal;
