import React from "react";

const CustomInputTextCodPostal = ({ value, setValue }) => {

  return (

    <div className="encuesta-text-box">
      <label htmlFor="cod_postal-input" className="encuesta-label">
        ¿Cuál es tu código postal?*
        </label>
      <input
        id="cod_postal-input"
        name="cod_postal-input"
        placeholder="Si no te lo sabes indica tu barrio/ciudad"
        type="text"
        pattern="[a-zA-Z0-9-]+"
        value={value}
        onChange={event => {    
          event.preventDefault();
          setValue(event.target.value);
        }}

      />
    </div>

  );
};
export default CustomInputTextCodPostal;