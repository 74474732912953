import React from "react";
import "./inicio.scss";
import SideBar from "../../components/NavBar/SideBar";
import "../../App.css";
import { Link } from "react-router-dom";
import inicio from "../../media/inicio2.jpg";
import chevron_down from "../../media/chevron-arrow-down.svg";
import CookieConsent from "react-cookie-consent";

function Inicio() {

	return (
		<React.Fragment>

			<CookieConsent
				buttonText="ACEPTAR"
				onAccept={() => {
				}}
				style={{
					background: "#d8cece",
					fontSize: "14px",
					color: '#373a47'
				}}
				buttonStyle={{
					background: "#14b6efd1",
					borderRadius: "15px",
					color: "white",
					fontFamily: "'Roboto', sans-serif",
					fontSize: "14px"
				}}
				enableDeclineButton
				declineButtonText="RECHAZAR"
				declineButtonStyle={{
					background: "#d82121de",
					borderRadius: "15px",
					color: "white",
					fontFamily: "'Roboto', sans-serif",
					fontSize: "14px"
				}}
				onDecline={() => {
				}}
			>
				Este portal web únicamente utiliza cookies propias con finalidad técnica, no recaba ni cede datos de carácter personal de los usuarios sin su conocimiento.{" "}
				<p style={{ marginTop: "10px" }}>
					Sin embargo, contiene enlaces a sitios web de terceros con políticas de privacidad ajenas a la de Economistas sin Fronteras que podrás decidir si aceptas o no cuando accedas a ellos. Si deseas más información, puedes consultar nuestro <Link target="_blank" to="/aviso_legal">Aviso Legal</Link>.
        </p>
			</CookieConsent>

			<div id="page-inicio">
				<header id="app-header" className="app-header-verde">
					<SideBar pageWrapId={"page-wrap"} outerContainerId={"page-inicio"} />
				</header>

				<main className="app-main">
					<div id="app-main-container" className="app-main-container">
						<div className="description-box">
							<h1 id="inicio-title" className="inicio-title">
								Visibilizar lo invisible
          		</h1>

							<div id="image-cuidados-box" className="image-cuidados-box">
								<img
									src={inicio}
									width="100%"
									alt="imagen representando quehaceres del hogar"
								/>
							</div>

							<div className="inicio-text-box">
								<p className="inicio-text">La economía se nutre de <span className="span-bold">nuestro tiempo</span>. Normalmente solo se presta atención a las horas que dedicamos a trabajar <span className="span-bold">a cambio de un salario</span>.</p>
								<p className="inicio-text">Pero también dedicamos tiempo a otras actividades fundamentales <span className="span-bold">para sostener la vida y para que el sistema funcione</span>.</p>
							</div>

							<div className="inicio-text-box">
								<p className="inicio-text-pregunta">
									¿Cuáles son estas actividades?</p><p className="inicio-text-pregunta">¿Cuánto tiempo les dedicamos?</p>
							</div>

							<div className="inicio-link-box">
								<Link className="inicio-link btn" to="/encuesta">
									<div className="btn-inicio">

										<span className="span-next">averígualo en 3 minutos</span>
										<img src={chevron_down} alt="icono de continuar" />
									</div>
								</Link>
							</div>

						</div>
					</div>
				</main>

				<footer className="footer-inicio">
					<p>CC Economistas sin Fronteras 2021 | <Link target="_blank" to="/aviso_legal">Aviso Legal</Link></p>
				</footer>
			</div>
		</React.Fragment>
	);
}

export default Inicio;
