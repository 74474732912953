import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSlider1 = withStyles({
  root: {
    color: "rgba(12, 21, 71, 1)",
    height: 8,
    paddingTop: 20,
    paddingBottom: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: { boxShadow: "0px 0px 0px 12px rgba(84, 199, 97, 0.16)" },
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSlider1 = ({ value, setValue, horas, minutos }) => {

  return (
    <div id="time-duo-box-1" className="time-duo-box">
      <div id="input-number-box" className="input-number-box">
        <input
          type="number"
          id="input-hours-1"
          min="00"
          max="60"
          step="1"
          value={horas}
          readOnly
        />
        <span>h:</span>
        <input
          type="number"
          id="input-minutes-1"
          min="00"
          max="30"
          step="30"
          value={minutos}
          readOnly
        />
        <span>m</span>
      </div>
      <PrettoSlider1
        valueLabelDisplay="auto"
        aria-label="slider_1"
        padding={10}
        step={0.5}
        defaultValue={0}
        min={0}
        max={60}
        value={value}
        onChange={(event, v) => {
          setValue(v);
          const tiempo = v.toString().split(".");
          const inputHoras = document.getElementById("input-hours-1");
          const inputMinutos = document.getElementById("input-minutes-1");
          inputHoras.value = tiempo[0] === "0" ? "00" : tiempo[0];
          inputMinutos.value = tiempo[1] === "5" ? 30 : "00";
        }}
      />
    </div>

  );
  
};
export default CustomSlider1;