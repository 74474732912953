import React, { useEffect, useState } from "react";
import "./resultados.scss";
import SideBar from "../../components/NavBar/SideBar";
import "../../App.css";
import { Link } from "react-router-dom";

import axios from "axios";

import iceberg from "../../media/iceberg.jpg";
import ayuntamiento from "../../media/ayuntamiento.svg";
import logoecosfron from "../../media/logoecosfron2.png";
import andecha from "../../media/andecha.svg";

import chevron_down from "../../media/chevron-arrow-down.svg";
import icono_calculadora from "../../media/calculadora.svg";

import whatsapp from "../../media/whatsapp.svg";
import telegram from "../../media/telegram.svg";
import twitter from "../../media/twitter.svg";
import facebook from "../../media/facebook.svg";

import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

import Grafica2 from '../../components/Gráfica2';

import ExportImage from '../../components/ExportImage/ExportImage';

function Resultados() {

  const [id_registro] = useState(
    parseInt(window.localStorage.getItem("id_registro"))
  );
  const [dataGrafico, setDataGrafico] = useState([]);
  const [colores, setColores] = useState([]);
  const [calculadora, setCalculadora] = useState([]);

  useEffect(() => {

    function loadGrafica() {
      let values = [];
      let colores = [];
      let calculadora = [];
      axios({
        method: "post",
        baseURL: "https://encuestatiempo.ecosfron.org/",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        url: "services/grafico_horas.php",
        data: {
          id_registro: id_registro,
        },
      }).then(function (response) {
        localStorage.setItem('horas_grafico', JSON.stringify(response.data.horas));
        localStorage.setItem('colores_grafico', JSON.stringify(response.data.colores));

        values = response.data.horas;
        colores = response.data.colores;
        calculadora = response.data.calculadora;
        setDataGrafico(values);
        setColores(colores);
        setCalculadora(calculadora);
      });
    }

    loadGrafica();
  }, [id_registro]);

  const options = {
    colors: colores,
    chart: {
      type: "pie",
      backgroundColor: '#fcfceb',
      style: {
        fontFamily: "Arial, sans-serif"
      }
    },
    title: {
      floating: false,
      margin: 30,
      style: {
        "color": "#0C1547",
        fontSize: "16px",
        fontWeight: 700,
        "text-align": "center",
      },
      text: "Distribución temporal de tu día promedio",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        showInLegend: false
      }
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}%</b>",
    },
    series: [
      {
        name: ["% horas"],
        data: dataGrafico,
        dataLabels: {
          distance: 40,
          connectorShape: "crookedLine",
          crookDistance: "110%",
          format: "{point.name}",
          connectorWidth: 2,
          style: {
            width: '15px',
            textOverflow: 'clip',
            fontSize: "1em",
          },
        },
      },
    ]
  };

  function calculateMoney() {
    const horas_moradas = calculadora[0] + calculadora[1] + calculadora[2];
    const calculo = horas_moradas * 52 * 7.04;

    const dinero_parrafo = document.getElementById("dinero-parrafo");
    dinero_parrafo.innerHTML = "";
    var dinero_valor = document.createElement("span");
    dinero_valor.innerHTML = new Intl.NumberFormat("es-ES").format(calculo.toFixed(0)) + "€";
    dinero_parrafo.appendChild(dinero_valor);

    const empleados_parrafo = document.getElementById("empleados-parrafo");
    empleados_parrafo.innerHTML = "";
    var empleados = document.createElement("span");

    const n_empleados = horas_moradas / 35;

    if (n_empleados === 1) {
      empleados.innerHTML = "una persona a jornada completa (35h/sem).";
    } else if (n_empleados > 1) {
      empleados.innerHTML = new Intl.NumberFormat("es-ES").format(n_empleados.toFixed(1)) + " personas a jornada completa (35h/sem).";
    } else {
      empleados.innerHTML = "una persona a " + new Intl.NumberFormat("es-ES").format(horas_moradas) + " horas semanales";
    }

    empleados_parrafo.appendChild(empleados);

    const info_money = document.getElementById("info-money");
    info_money.classList.remove("hidden");
  }

  return (
    <React.Fragment>
      <div id="page-resultados">
        <header id="app-header" className="app-header-verde">
          <SideBar
            pageWrapId={"page-wrap"}
            outerContainerId={"page-resultados"}
          />
          <h1 id="header-title" className="header-title">
            Tus resultados
          </h1>
        </header>

        <main className="app-main">
          <div id="app-main-container" className="app-main-container">
            <div id="highchart-box-1" className="highchart-box">
              <h2 id="titulo-grafica-1">¿A qué dedicas tu tiempo?</h2>
              <PieChart highcharts={Highcharts} options={options} />
              <p style={{ marginTop: 1, fontWeight: 900 }}><span className="span-legend"></span> Trabajo doméstico y de cuidados</p>
              <p style={{ marginTop: 12 }}>De todas las horas que trabajas, la economía convencional sólo considera productivas las dedicadas al trabajo remunerado. Pero en los hogares también se trabaja y se produce. Estos trabajos son imprescindibles para que la economía funcione.</p>
            </div>

            <Grafica2 />

            <div className="calculo-box">
              <h2>¿Cómo valoramos el tiempo dedicado al trabajo doméstico y los cuidados?</h2>
              <p className="grafica-parrafo">
                Como ejercicio teórico puede resultar útil el cálculo monetario de estos trabajos.
              </p>
              <p className="grafica-parrafo">
                Si tomamos como referencia el salario mínimo por hora de las trabajadoras domésticas (7,04 euros) y lo multiplicamos por el tiempo de tu trabajo doméstico y de cuidados...
              </p>

              <div className="botones-box">
                <button className="boton-resultado" onClick={calculateMoney}>
                  calcular resultado
                  <img
                    src={icono_calculadora}
                    width="22px"
                    alt="icono de calculadora"
                  />
                </button>
              </div>

              <div id="info-money" className="hidden">
                <p id="calculadora-parrafo" className="calculadora-parrafo">
                  El trabajo doméstico y de cuidados que realizas durante un año valdría:
                </p>
                <p id="dinero-parrafo" className="calculadora-parrafo"></p>

                <p id="calculadora-parrafo" className="calculadora-parrafo">
                  Para realizar estos trabajos en tu lugar habría que contratar a:
                </p>
                <p id="empleados-parrafo" className="calculadora-parrafo"></p>
              </div>
            </div>

            <div className="iceberg-box">
              <h2>La economía es un iceberg</h2>

              <div>
                <img
                  src={iceberg}
                  width="100%"
                  alt="imagen de un iceberg mostrando las desventajas que sufren las mujeres"
                />
              </div>

              <p className="iceberg-parrafo">
                Hay que #VisibilizarLoInvisible: reconocer socialmente la importancia del trabajo doméstico y de cuidados, incluirlo en las políticas públicas y asumirlo como una responsabilidad compartida por el conjunto de la sociedad.
              </p>
            </div>

            <div className="compartir-box">
              <h2>Comparte la encuesta</h2>
              <div className="share-btn-box">

                <a target="_blank" rel="noreferrer" href="https://wa.me/?text=Hay actividades indispensables para que la economía funcione, ¿sabes cuáles? ¿cuánto tiempo les dedicas? Descúbrelo https://encuestatiempo.ecosfron.org" data-action="share/whatsapp/share">
                  <img src={whatsapp} alt="icono de whatsapp para compartir" />
                </a>

                <a target="_blank" rel="noreferrer" href="https://t.me/share/url?url=https://encuestatiempo.ecosfron.org&text=Hay actividades indispensables para que la economía funcione, ¿sabes cuáles? ¿cuánto tiempo les dedicas? Descúbrelo">
                  <img src={telegram} alt="icono de telegram para compartir" />
                </a>

                <a target="_blank" rel="noreferrer" href="https://twitter.com/intent/tweet?text=Hay actividades indispensables para que la economía funcione, ¿sabes cuáles? ¿cuánto tiempo les dedicas? Descúbrelo&hashtags=VisilizarLoInvisible&url=https://encuestatiempo.ecosfron.org">
                  <img src={twitter} alt="icono de twitter para compartir" />
                </a>

                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fapps.incamedio.com%2Feconomistassinfronteras%2Fbuild" className="fb-xfbml-parse-ignore">
                  <img src={facebook} alt="icono de facebook para compartir" />
                </a>
              </div>


              <div className="btn-share-box">

                <ExportImage />

                <Link className="resultados-link btn" to="/mas_informacion">
                  <div className="div-btn-resultados mas-info">
                    <span className="span-next">¿quieres más información?</span>
                    <img src={chevron_down} alt="icono de ir a resultados" />
                  </div>
                </Link>
              </div>
            </div>

            <div className="logos-empresas-box">
              <div className="logo-emp-box">
                <img
                  src={logoecosfron}
                  alt="imagen logo de Economistas sin Fronteras"
                />
              </div>
              <div className="logo-emp-box">
                <img src={andecha} alt="imagen logo Andecha" />
              </div>
            </div>
            <p className="compartir-parrafo">
              Financiado por:
            </p>
            <div className="logo-emp-box">
              <img
                src={ayuntamiento}
                alt="imagen logo Ayuntamiento de Madrid"
              />
            </div>
          </div>
        </main>

        <footer className="footer-resultados">
          <p>
            CC Economistas sin Fronteras 2021 |{" "}
            <Link target="_blank" to="/aviso_legal">Aviso Legal</Link>
          </p>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default Resultados;
