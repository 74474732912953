import React, { useEffect, useState } from "react";
import "./encuesta.scss";
import SideBar from "../../components/NavBar/SideBar";
import "../../App.css";
import { useHistory } from "react-router-dom";

import PrettoSlider1 from '../../components/PrettoSlider1/PrettoSlider1';
import PrettoSlider2 from '../../components/PrettoSlider2/PrettoSlider2';
import PrettoSlider3 from '../../components/PrettoSlider3/PrettoSlider3';
import PrettoSlider4 from '../../components/PrettoSlider4/PrettoSlider4';
import PrettoSlider5 from '../../components/PrettoSlider5/PrettoSlider5';
import PrettoSlider6 from '../../components/PrettoSlider6/PrettoSlider6';
import PrettoSlider7 from '../../components/PrettoSlider7/PrettoSlider7';
import PrettoSlider8 from '../../components/PrettoSlider8/PrettoSlider8';
import PrettoSlider9 from '../../components/PrettoSlider9/PrettoSlider9';

import Modal from '../../components/Modal/Modal';
import UseModal from '../../components/Modal/UseModal';

import chevron_down from "../../media/chevron-arrow-down.svg";

import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import { Link as LinkScroll } from "react-scroll";


function Encuesta() {

  const { isShowing, toggle } = UseModal();

  const [scroll, setScroll] = useState(0);

  useEffect(() => {

    const itemFocus = document.getElementById("menu-2");
    itemFocus.classList.add("link-activo");

    let progressBarHandler = (e) => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}` > 0.98 ? 1 : `${totalScroll / windowHeight}`;

      setScroll(scroll);
    };

    window.addEventListener("scroll", progressBarHandler, { passive: true });

    return () => window.removeEventListener("scroll", progressBarHandler);
  }, [scroll, setScroll]);

  /* Barra progreso */
  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  };


  let history = useHistory();

  function checkValues(e) {

    e.preventDefault();

    const trabajo = parseFloat(localStorage.getItem("trabajo"));
    const estudios = parseFloat(localStorage.getItem("estudios"));
    const alimentacion = parseFloat(localStorage.getItem("alimentacion"));
    const hogar = parseFloat(localStorage.getItem("hogar"));
    const cuidados = parseFloat(localStorage.getItem("cuidados"));
    const gestiones = parseFloat(localStorage.getItem("gestiones"));
    const autocuidado = parseFloat(localStorage.getItem("autocuidado"));
    const ocio = parseFloat(localStorage.getItem("ocio"));
    const activismo = parseFloat(localStorage.getItem("activismo"));

    const total_horas =
      trabajo +
      estudios +
      alimentacion +
      hogar +
      cuidados +
      gestiones +
      autocuidado +
      ocio +
      activismo;


    if (total_horas === 0) {
      alert(
        "Todos los valores de la encuesta son cero. Por favor indique sus cifras."
      );
      return false;
    } else {
      history.push("/sobre_ti");
    }

  }

  return (
    <React.Fragment>
      <div id="page-encuesta">
        <header id="app-header" className="app-header-verde">
          <SideBar
            pageWrapId={"page-wrap"}
            outerContainerId={"page-encuesta"}
          />
          <h1 id="header-title" className="header-title">
            {"La encuesta"}
          </h1>
        </header>

        <main className="app-main">
          <div id="progressBarContainer">
            <div
              id="progressBar"
              style={{ transform: `scale(${scroll}, 1)`, opacity: `${scroll}` }}
            />
          </div>

          <div id="app-main-container" className="app-main-container">

            <div id="pregunta-1-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas al trabajo remunerado?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Horas de trabajo y de desplazamiento para llegar al lugar de
                  trabajo
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <PrettoSlider1 />
              </div>

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-2-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-2-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas a los estudios?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Incluye todo tipo de formación: reglada, no reglada, presencial, online...
                </p>
              </div>
              
              <PrettoSlider2 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-3-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-3-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas a tareas relativas a la alimentación?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Incluye comprar, guardar y preparar alimentos, cocinar, fregar vajilla, recoger cocina... todo menos ¡el tiempo que dedicas a comer!
                </p>
              </div>

              <PrettoSlider3 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-4-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-4-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas al mantenimiento básico del
                  hogar?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Tareas relacionadas con el orden y la limpieza en general
                </p>
              </div>

              <PrettoSlider4 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-5-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-5-box" className="section-box">

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas a gestiones y compras?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Actividades relativas a gestiones administrativas con
                  instituciones públicas, centros educativos, bancos, compañías
                  telefónicas, etc; compras (excepto alimentación); reparaciones
                  y mantenimiento (coche, hogar)
                </p>
              </div>


              <PrettoSlider6 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-6-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-6-box" className="section-box">

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas a cubrir necesidades básicas?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Comer, dormir, higiene personal...
                </p>
              </div>

              <PrettoSlider7 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-7-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>
            <div id="pregunta-7-box" className="section-box">

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas al cuidado de otras personas?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Cuidado físico y emocional de personas con las que convives y
                  con las que no {/* <button className="button-close-modal" onClick={toggle}><img src={info_button} width="100%" alt="icono de abrir popup de información" /></button> */}
                </p>

              </div>

              <Modal
                isShowing={isShowing}
                hide={toggle}
              />

              <PrettoSlider5 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-8-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>

            <div id="pregunta-8-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas al ocio?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Deporte, lectura, redes sociales, ver películas, quedar con
                  amigos/as...
                </p>
              </div>

              <PrettoSlider8 />

              <div className="encuesta-link-box">
                <LinkScroll
                  activeClass="active"
                  to="pregunta-9-box"
                  spy={true}
                  smooth={true}
                  /* offset={-55} */
                  duration={1000}
                  className="encuesta-link btn"
                >
                  <div className="div-btn-encuesta">
                    <span className="span-next">siguiente</span>
                    <img src={chevron_down} alt="icono de pregunta siguiente" />
                  </div>
                </LinkScroll>
              </div>
            </div>

            <div id="pregunta-9-box" className="section-box">
              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text">
                  ¿Cuántas horas a la <span id="semana-span" className="semana-span">semana</span> dedicas a participación comunitaria
                  y activismo?
                </p>
              </div>

              <div className="encuesta-text-box pregunta-horas">
                <p className="encuesta-text pregunta">
                  Participar en asociaciones sociales y políticas (vecinales, ecologistas, escolares, del tercer sector, partidos políticos, sindicatos...) y/u otros colectivos
                </p>
              </div>

              <PrettoSlider9 />

              <div className="encuesta-link-box btn-continuar-box">

                <button
                  className="encuesta-button"
                  onClick={checkValues}
                >
                  <div className="div-button-encuesta" >
                    <span className="span-next">Continuar</span>
                    <img src={chevron_down} alt="icono de continuar" />
                  </div>
                </button>

              </div>
            </div>

          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default Encuesta;