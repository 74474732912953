import React from "react";
import "./avisolegal.scss";
import SideBar from "../../components/NavBar/SideBar";
import "../../App.css";
import cx from "classnames";
import Collapse from "@kunukn/react-collapse";
import { Link } from "react-router-dom";


class AvisoLegal extends React.Component {
	state = {
		isOpen1: false,
		isOpen2: false,
	};

	componentDidMount() {
		const itemFocus = document.getElementById("menu-5");
		itemFocus.classList.add("link-activo");
	}

	render() {

		return (
			<div id="page-avisolegal">
				<header id="app-header" className="app-header">
					<SideBar pageWrapId={"page-wrap"} outerContainerId={"page-avisolegal"} />
					<h1 id="header-title" className="header-title">Aviso legal</h1>
				</header>

				<main className="app-main">
					<div id="app-main-container" className="app-main-container">

						<h2 id="title-about" className="title-about">Aviso legal</h2>

						<button
							className={cx("app__toggle", {
								"app__toggle--active": this.state.isOpen1
							})}
							onClick={() => this.toggle(1)}
						>
							<span className="app__toggle-title">
								Política de privacidad y protección de datos personales de EsF
							</span>
							<div className="rotate90">
								<svg
									className={cx("icon", { "icon--expanded": this.state.isOpen1 })}
									viewBox="6 0 12 24"
								>
									<polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
								</svg>
							</div>
						</button>
						<Collapse
							isOpen={this.state.isOpen1}
							className={
								"app__collapse " +
								(this.state.isOpen1 ? "app__collapse--active" : "")
							}
						>
							<div className="accordion-content">

								<p className="parrafo-accordion">
									De conformidad con lo previsto en el <a target="_blank" rel="noreferrer" href="https://www.agpd.es/portalwebAGPD/canaldocumentacion/legislacion/union_europea/reglamentos/common/pdfs/Reglamento_UE_2016-679_Proteccion_datos_DOUE.pdf">Reglamento General de Protección de Datos</a> europeo, se informa a los usuarios de esta página Web de que los datos de carácter personal que faciliten a través de ella, tanto cumplimentando cualquier formulario, como a través de correo electrónico o por cualquier otro medio, serán tratados de conformidad con la presente Política de Protección de Datos.
							</p>
								<h4 className="parrafo-accordion">
									¿Quién es el responsable del tratamiento de tus datos personales?
							</h4>
								<p className="parrafo-accordion">
									El responsable es la FUNDACIÓN ECONOMISTAS SIN FRONTERAS (en adelante “EsF”), NIF: G82316522,  con domicilio en Calle Gaztambide nº 50, 28015 Madrid (España). Teléfono: 915 497 279. Correo electrónico <a target="_blank" rel="noreferrer" href="mailto:ecosfron@ecosfron.org">ecosfron@ecosfron.org</a>
								</p>
								<h4 className="parrafo-accordion">
									¿Quién es la Delegada de Protección de Datos?
							</h4>
								<p className="parrafo-accordion">
									Es la persona que vela por el cumplimiento diligente de la normativa de protección de datos de carácter personal. Puedes contactar con nuestra Delegada de Protección de Datos en la dirección postal de EsF, o en la dirección electrónica ecosfron@ecosfron.org
							</p>
								<h4 className="parrafo-accordion">
									¿Cómo hemos obtenido tus datos de carácter personal?
							</h4>
								<p className="parrafo-accordion">
									Tus datos de carácter personal los hemos obtenido a través de las relaciones previas que has mantenido con EsF, o con motivo de las relaciones que mantienes en la actualidad con nuestra entidad, bien por tu condición de socia, voluntaria, colaboradora, beneficiaria o bien por haber solicitado que te remitamos alguno de nuestros boletines de información.
							</p>
								<h4 className="parrafo-accordion">
									¿Qué datos personales tratamos?
								</h4>
								<p className="parrafo-accordion">
									Tus datos, que trataremos para las finalidades legítimas que más adelante se explican, son los siguientes:
								</p>
								<h4 className="parrafo-accordion">
									Datos necesarios para mantener la relación contigo:
								</h4>

								<ul style={{ paddingLeft: 40 + 'px' }}>
									<li>Nombre y apellido</li>
									<li>Dirección de correo electrónico</li>
								</ul>


								<p className="parrafo-accordion">
									También te informamos de que trataremos aquellos datos adicionales que voluntariamente nos facilites durante las futuras relaciones e interacciones que mantengas con EsF, incluidos los que nos aportes mediante una red social u otra aplicación. Estos datos dependen de tu propia configuración de privacidad, uso de la red social o aplicación, así como de las políticas de privacidad propias de cada red social o aplicación, por lo que te recomendamos leer las mismas con atención antes de facilitarnos datos a través de dichas redes sociales o aplicaciones.
								</p>
								<h4 className="parrafo-accordion">
									¿Para qué tratamos tus datos personales?
								</h4>
								<p className="parrafo-accordion">
									Para cumplir con nuestra misión de contribuir a generar cambios que permitan alcanzar unas estructuras económicas y sociales justas y solidarias, tratamos los datos personales para las siguientes finalidades:
								</p>

								<ul style={{ paddingLeft: 40 + 'px' }}>
									<li>Enviar información sobre EsF vía correo electrónico, incluso cuando nuestra relación haya terminado, salvo que el interesado manifieste lo contrario.</li>
									<li>Solicitar que te asocies a EsF, o incrementar tu cuota y otros tipos de participación, si eres socia o socio.</li>
									<li>Invitar a actos o eventos que organice EsF o las redes a las que pertenece.</li>
									<li>Difundir informaciones, campañas o eventos relacionados con el ámbito de actuación de EsF.</li>
									<li>Realizar encuestas de valoración y evaluación.</li>
								</ul>

								<p className="parrafo-accordion pregunta">
									La persona interesada puede oponerse a recibir este tipo de comunicaciones promocionales, ahora o en cualquier otro momento, enviando un correo electrónico a la dirección electrónica de nuestra Delegada de Protección de Datos, ecosfron@ecosfron.org, o dirigiendo una solicitud a nuestra dirección postal, a la atención de la Delegada de Protección de Datos.
								</p>
								<h4 className="parrafo-accordion">
									¿Por qué motivo podemos tratar tus datos personales?
								</h4>
								<p className="parrafo-accordion">
									El tratamiento de tus datos personales relacionado con el mantenimiento de tu relación con EsF como persona socia, donante o voluntaria está legitimado al resultar necesario para dar cumplimiento a las obligaciones contractuales derivadas de dicha relación.
								</p>
								<p className="parrafo-accordion">
									El tratamiento de tus datos personales para el envío de los boletines informativos responde a un interés legítimo de nuestra entidad y está autorizado por la normativa vigente.
								</p>
								<h4 className="parrafo-accordion">
									¿Cuándo y por qué motivo podemos facilitar tus datos a terceros?
								</h4>
								<p className="parrafo-accordion">
									No cederemos sus datos personales a terceros, salvo que estemos obligados por una ley.
								</p>
								<h4 className="parrafo-accordion">
									¿Realizamos transferencias internacionales de datos?
								</h4>
								<p className="parrafo-accordion">
									No. EsF no  tiene contratados los servicios de proveedores tecnológicos ubicados en países que no disponen de normativa equivalente a la europea (“terceros países”).
								</p>
								<h4 className="parrafo-accordion">
									¿Durante cuánto tiempo guardaremos tus datos?
								</h4>
								<p className="parrafo-accordion">
									Tus datos personales se conservarán mientras se mantenga tu relación con EsF  y, tras la finalización de dicha relación por cualquier causa, durante los plazos de prescripción legales que sean de aplicación. En este supuesto, se tratarán a los solos efectos de acreditar el cumplimiento de nuestras obligaciones legales o contractuales. Finalizados dichos plazos de prescripción, tus datos serán eliminados o, alternativamente, anonimizados.
								</p>
								<h4 className="parrafo-accordion">
									¿Cuáles son tus derechos?
								</h4>
								<p className="parrafo-accordion">
									Puedes ejercitar tus derechos de acceso, rectificación, supresión y portabilidad, limitación u oposición al tratamiento, a través de las direcciones postal y electrónica indicadas.
								</p>
								<p className="parrafo-accordion">
									Asimismo, si consideras que el tratamiento de sus datos personales vulnera la normativa o tus derechos de privacidad, puedes presentar una reclamación:
								</p>

								<ul style={{ paddingLeft: 40 + 'px' }}>
									<li>A nuestra Delegada de Protección de Datos, a través de las direcciones postal y electrónica indicadas.</li>
									<li>Ante la Agencia Española de Protección de Datos, a través de su sede electrónica, o de su dirección postal.</li>
								</ul>
								<p className="parrafo-accordion"></p>

								<button onClick={() => this.toggle(1)} className="app__button">
									Cerrar
            		</button>

							</div>

						</Collapse>


						<button
							className={cx("app__toggle", {
								"app__toggle--active": this.state.isOpen1
							})}
							onClick={() => this.toggle(2)}
						>
							<span className="app__toggle-title">Política de cookies</span>
							<div className="rotate90">
								<svg
									className={cx("icon", { "icon--expanded": this.state.isOpen2 })}
									viewBox="6 0 12 24"
								>
									<polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
								</svg>
							</div>
						</button>
						<Collapse
							isOpen={this.state.isOpen2}
							className={
								"app__collapse " +
								(this.state.isOpen2 ? "app__collapse--active" : "")
							}
						>
							<div className="accordion-content">

								<p className="parrafo-accordion">
									Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Asimismo, las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc.
								</p>

								<h4 className="parrafo-accordion">
									Cookies utilizadas en este sitio web
								</h4>

								<p className="parrafo-accordion">
									Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible.
								</p>


								<p className="parrafo-accordion">
									Este sitio web utiliza las siguientes cookies propias:
									</p>

								<p className="parrafo-accordion">
									Cookies de sesión, para garantizar que los usuarios que escriban comentarios en el blog sean humanos y no aplicaciones automatizadas. De esta forma se combate el spam.
								</p>

								<p className="parrafo-accordion">
									Este sitio web utiliza las siguientes cookies de terceros:
								</p>

								<p className="parrafo-accordion">
									Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.
								</p>

								<p className="parrafo-accordion">
									Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me gusta o Compartir.
								</p>

								<h4 className="parrafo-accordion">
									Desactivación o eliminación de cookies
								</h4>

								<p className="parrafo-accordion">
									En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web. Estas acciones se realizan de forma diferente en función del navegador que esté usando.
								</p>

								<h4 className="parrafo-accordion">
									Notas adicionales
								</h4>

								<p className="parrafo-accordion">
									Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.
								</p>
								<p className="parrafo-accordion">
									Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.
								</p>
								<p className="parrafo-accordion">
									En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas.
								</p>
								<p className="parrafo-accordion">
									En el caso de las <a target="_blank" rel="noreferrer" href="https://policies.google.com/technologies/cookies?hl=es">cookies de Google Analytics</a>, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Si desea información sobre el uso que Google da a las cookies <a target="_blank" rel="noreferrer" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1">le adjuntamos este otro enlace</a>.
								</p>

								<p className="parrafo-accordion">
									Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de contacto.
								</p>


								<button onClick={() => this.toggle(2)} className="app__button">
									Cerrar
            		</button>

							</div>

						</Collapse>
					</div>

				</main>
				<footer className="footer-avisolegal">
					<p>CC Economistas sin Fronteras 2021 | <Link target="_blank" to="/aviso_legal">Aviso Legal</Link></p>
				</footer>
			</div>
		);
	}

	toggle = index => {
		let collapse = "isOpen" + index;

		this.setState(prevState => ({ [collapse]: !prevState[collapse] }));
	};

}

export default AvisoLegal;
