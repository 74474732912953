import React, { useEffect } from "react";
import SideBar from "../../components/NavBar/SideBar";
import "./masinfo.scss";
import "../../App.css";
import { Link } from "react-router-dom";

function MasInfo() {
	useEffect(() => {
		const itemFocus = document.getElementById("menu-3");
		itemFocus.classList.add("link-activo");
	});

	const download = e => {

		e.preventDefault();
		e.stopPropagation();

		if (e.target.href === "") {
			return false;
		}

		const enlace = e.target.href.split("/");
		const position = enlace.length;

		fetch(e.target.href, {
			method: "GET",
			headers: {}
		})
			.then(response => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", enlace[position - 1]);
					link.click();
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	return (
		<React.Fragment>
			<div id="page-masinfo">
				<header id="app-header" className="app-header">
					<SideBar pageWrapId={"page-wrap"} outerContainerId={"page-masinfo"} />
					<h1 id="header-title" className="header-title">
						Más información
          </h1>
				</header>

				<main className="app-main">
					<div id="app-main-container" className="app-main-container">
						<div className="masinfo-content">
							<div className="masinfo-text-box">
								<h2 id="title-masinfo" className="title-masinfo">
									Más información
                </h2>
								<p className="masinfo-ul-title">
									Ilustraciones e infografías (descarga directa)
                </p>
								<ul className="masinfo-lista">
									<li>
										<a
											href="https://encuestatiempo.ecosfron.org/descargas/visibilizar_lo_invisible.jpg"
											onClick={e => download(e)}
										>
											Visibilizar lo invisible
                    </a>
									</li>
									<li>
										<a
											href="https://encuestatiempo.ecosfron.org/descargas/el_iceberg.jpg"
											onClick={e => download(e)}
										>
											El iceberg
                    </a>
									</li>
									<li>
										<a
											href="https://encuestatiempo.ecosfron.org/descargas/trabajos_compartidos.jpg"
											onClick={e => download(e)}
										>
											Trabajos domésticos y de cuidados: ¡mejor compartidos!
                    </a>
									</li>
									<li>
										<a
											href="https://encuestatiempo.ecosfron.org/descargas/piramide_sostenibilidad.jpg"
											onClick={e => download(e)}
										>
											La pirámide de la (in)sostenibilidad de la vida
                    </a>
									</li>
								</ul>
								<p className="masinfo-ul-title">
									Para saber más (enlaces externos)
                </p>
								<ul className="masinfo-lista">
									<li>
										<a
											rel="noreferrer"
											href="http://redeconomiafeminista.net/portfolio-type/materiales/"
											target="_blank"
										>
											Para leer, ver y escuchar: recursos de economía feminista
                    </a>
									</li>
									<li>
										<a
											rel="noreferrer"
											href="https://ecosfron.org/portfolio/dossieres-esf-no-29-economia-feminista-visibilizar-lo-invisible/"
											target="_blank"
										>
											Dossier economía feminista
                    </a>
									</li>
									<li>
										<a
											rel="noreferrer"
											href="https://ecosfron.org/portfolio/hacia-una-economia-mas-justa-manual-de-corrientes-economicas-heterodoxas"
											target="_blank"
										>
											Hacia una economía justa: manual de corrientes económicas
											heterodoxas
                    </a>
									</li>
									<li>
										<a
											rel="noreferrer"
											href="https://ecosfron.org/portfolio/guia-didactica-una-economia-de-mucho-cuidado/"
											target="_blank"
										>
											Guía didáctica: una economía de mucho cuidado
                    </a>
									</li>
									<li>
										<a
											rel="noreferrer"
											href="https://ecosfron.org/los-cuidados-una-responsabilidad-a-compartir/"
											target="_blank"
										>
											Los cuidados: una responsabilidad a compartir
                    </a>
									</li>
								</ul>
							</div>
						</div>

					</div>
				</main>

				<footer className="footer-masinfo">
					<p>
						CC Economistas sin Fronteras 2021 |{" "}
						<Link target="_blank" to="/aviso_legal">Aviso Legal</Link>
					</p>
				</footer>
			</div>
		</React.Fragment>
	);
}

export default MasInfo;
