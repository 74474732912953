import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const Modal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <p>
          Seguramente cuides mientras haces otras cosas, en esta encuesta básica no lo podemos ver. Al cumplimentarla ten en cuenta que los cuidados ¡no suelen parar el fin de semana!
        </p>
        <button
          data-dismiss="modal" aria-label="Close"
          className="modal-close-button"
          onClick={hide}
        >
          <div className="div-button-modal" >Cerrar</div>
        </button>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;