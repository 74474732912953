import React, { useEffect } from "react";
import CustomInputTextGenero from "./CustomInputTextGenero";

function InputTextGenero() {

  const ls_value = localStorage.getItem("genero");

  const [value, setValue] = React.useState(ls_value == null ? '' : ls_value);

  useEffect(() => {
    localStorage.setItem("genero", value);
  }, [value]);

  return <CustomInputTextGenero label="Input_genero" value={value} setValue={setValue} />;
}

export default InputTextGenero;