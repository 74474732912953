import React, { useRef, useEffect } from 'react';
import "../../App.css";
import "./ExportImage.scss";

import { exportComponentAsJPEG } from 'react-component-export-image';
import descarga from "../../media/descarga.svg";
import calendario_resultados from '../../media/calendario_resultados.jpg';
import resultados from '../../media/resultados.jpg';

import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

const Grafica1 = () => {

  let colores = JSON.parse(localStorage.getItem('colores_grafico'));
  let dataGrafico = JSON.parse(localStorage.getItem('horas_grafico'));

  const options = {
    colors: colores,
    chart: {
      type: "pie",
      backgroundColor: '#fcfceb',
      style: {
        width: 100,
        fontFamily: "Arial, sans-serif"
      }
    },
    title: {
      margin: 30,
      text: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        showInLegend: false
      }
    },
    series: [
      {
        name: ["% horas"],
        data: dataGrafico,
        dataLabels: {
          distance: 30,
          connectorShape: "crookedLine",
          crookDistance: "110%",
          format: "{point.name}",
          connectorWidth: 2,
          style: {
            width: '15px',
            textOverflow: 'clip',
            fontSize: "1em",
          },
        },
      },
    ]
  };

  return <PieChart highcharts={Highcharts} options={options} />;
};

const ComponentToExport = React.forwardRef((props, ref) => (
  <div className="image-export-body">
    <div className="image-export-box" ref={ref}>
      <div className="titulo-imagen-box">
        <h3 className="titulo-imagen">mi día promedio</h3>
        <img
          src={calendario_resultados}
          width="140px"
          alt="icono de un calendario con el día 8"
        />
      </div>
      <div className="grafica-image-box">
        <Grafica1 />
      </div>

      <p className="frases-trabajo">Mi trabajo doméstico y de cuidados durante un año valdría: <span id="span-dinero" className="span-negrita"></span></p>

      <p className="frases-trabajo">Tendría que contratar a: <span id="span-empleado" className="span-negrita"></span></p>
      <img
        src={resultados}
        width="100%"
        alt="icono de tareas domésticas y de cuidados"
      />
      <p className="frase-tareas">Las tareas domésticas y de cuidados ¡mejor compartidas!</p>
    </div>
  </div>
));

const ExportImage = () => {

  const componentRef = useRef();

  function exportarImagen(e) {
    e.preventDefault();
    e.stopPropagation();
    exportComponentAsJPEG(componentRef);
  }

  useEffect(() => {

    let calculadora = [];

    function calculateMoney() {

      calculadora[0] = parseFloat(localStorage.getItem("hogar"));
      calculadora[1] = parseFloat(localStorage.getItem("cuidados"));
      calculadora[2] = parseFloat(localStorage.getItem("alimentacion"));

      const horas_moradas = calculadora[0] + calculadora[1] + calculadora[2];
      const calculo = horas_moradas * 52 * 7.04;

      const dinero_img = document.getElementById("span-dinero");
      const dinero_valor_img = new Intl.NumberFormat("es-ES").format(calculo.toFixed(0)) + " euros";

      dinero_img.innerHTML = `${dinero_valor_img}`;

      const empleados_img = document.getElementById("span-empleado");

      const n_empleados = horas_moradas / 35;

      if (n_empleados === 1) {
        empleados_img.innerHTML = "una persona a jornada completa (35h/sem)";
      } else if (n_empleados > 1) {
        empleados_img.innerHTML = new Intl.NumberFormat("es-ES").format(n_empleados.toFixed(1)) + " personas a jornada completa (35h/sem)";
      } else {
        empleados_img.innerHTML = "una persona a " + new Intl.NumberFormat("es-ES").format(horas_moradas) + " horas semanales";
      }

    }

    calculateMoney();
  });

  return (
    <React.Fragment>
      <ComponentToExport ref={componentRef} />
      <button className="resultados-link btn" onClick={exportarImagen}>
        <div className="div-btn-descarga descarga">
          <span className="span-descarga">
            descarga tus resultados
          </span>
          <img src={descarga} alt="icono de descargar resultados" />
        </div>
      </button>
    </React.Fragment>
  );
};

export default ExportImage;