import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSliderEdad = withStyles({
  root: {
    color: "#0C1547",
    height: 0,
    paddingTop: 2,
    paddingBottom: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSliderEdad = ({ value, setValue }) => {

  return (
    <div className="encuesta-text-box">
      
      <p>¿Cuál es tu edad?</p>
      <PrettoSliderEdad
        valueLabelDisplay="auto"
        aria-label="slider_edad"
        padding={10}
        step={1}
        defaultValue={10}
        min={10}
        max={90}
        value={value}
        onChange={(event, v) => {
          setValue(v);
        }}
      />
    </div>
  );
};
export default CustomSliderEdad;