import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const ModalPrivacidad = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">

        <p><span className="modal-span">Responsable del tratamiento:</span> los datos tratados en el presente formulario, serán tratados por la FUNDACIÓN ECONOMISTAS SIN FRONTERAS con CIF C82316522 y domicilio en CALLE GAZTAMBIDE 50 - 28015 BAJO MADRID.</p>

        <p><span className="modal-span">Finalidad:</span> mantenimiento de las actividades formativas que realizamos desde FUNDACIÓN ECONOMISTAS SIN FRONTERAS.</p>

        <p><span className="modal-span">Legitimación:</span> consentimiento del interesado (artículo 6.1.a RGPD) otorgado marcando la correspondiente casilla de verificación.</p>

        <p><span className="modal-span">Destinatarios:</span> sus datos no serán cedidos a ninguna empresa, salvo obligación legal.</p>

        <p><span className="modal-span">Derechos:</span> puede acceder, rectificar, suprimir, portabilidad de los datos, limitación u oposición a su tratamiento escribiendo a ecosfron@ecosfron.org o en la dirección indicada en el apartado Responsable del Tratamiento, adjuntando fotocopia de su DNI o documento equivalente.</p>

        <p><span className="modal-span">Información adicional:</span> puede consultar la información adicional y detallada sobre protección de datos en la sección Aviso Legal y en nuestra página web: www.ecosfron.org</p>
        <button
          data-dismiss="modal" aria-label="Close"
          className="modal-close-button"
          onClick={hide}
        >
          <div className="div-button-modal" >Cerrar</div>
        </button>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default ModalPrivacidad;