import React, { useEffect } from "react";
import "./about.scss";
import SideBar from "../../components/NavBar/SideBar";
import "../../App.css";
import logoecosfron from "../../media/logoecosfron.svg";
import { Link } from "react-router-dom";

function About() {

	useEffect(() => {
		const itemFocus = document.getElementById("menu-4");
		itemFocus.classList.add("link-activo");
	});

	return (
		<React.Fragment>
			<div id="page-about">
				<header id="app-header" className="app-header">
					<SideBar pageWrapId={"page-wrap"} outerContainerId={"page-about"} />
					<h1 id="header-title" className="header-title">Sobre Economistas sin Fronteras</h1>
				</header>

				<main className="app-main">
					<div id="app-main-container" className="app-main-container">
						<div className="about-content">
							<div className="about-text-box">
								<h2 id="title-about" className="title-about">Sobre Economistas sin Fronteras</h2>
								<p className="about-text">En Economistas sin Fronteras llevamos más de 20 años trabajando por una economía justa, solidaria y sostenible.</p>

								<p className="about-text">Apostamos por una economía que ponga la sostenibilidad de la vida en el centro; una economía en la que los mercados sean un medio y no un fin en sí mismos.</p>

								<p className="about-text">Si quieres saber más sobre cómo lo hacemos visita nuestra web <a target="_blank" rel="noreferrer" href="https://ecosfron.org/">www.ecosfron.org</a> o nuestras redes sociales: <a target="_blank" rel="noreferrer" href="https://twitter.com/EconomiaJusta">Twitter</a>, <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Ecosfron">Facebook</a>, <a target="_blank" rel="noreferrer" href="https://www.instagram.com/economistassinfronteras/">Instagram</a>.</p>
							</div>
						</div>

						<div className="logo-emp-box">
							<img
								src={logoecosfron}
								alt="imagen logo de Economistas sin Fronteras"
							/>
						</div>
					</div>
				</main>

				<footer className="footer-about">
					<p>CC Economistas sin Fronteras 2021 | <Link target="_blank" to="/aviso_legal">Aviso Legal</Link></p>
				</footer>
			</div>
		</React.Fragment>
	);
}

export default About;
