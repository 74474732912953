import React from "react";
import ModalGenero from '../Modal/ModalGenero';
import UseModal from '../Modal/UseModal';

const CustomInputTextGenero = ({ value, setValue }) => {

  const { isShowing, toggle } = UseModal();
  return (

    <div className="encuesta-text-box">

      <ModalGenero
        isShowing={isShowing}
        hide={toggle}
      />


      <label htmlFor="gender-input" className="encuesta-label info-popup-line">
        ¿Cuál es tu género?*
      </label>
      <input
        name="gender-input"
        type="text"
        placeholder="Ejemplo: femenino"
        pattern="[a-zA-Z0-9-]+"
        value={value}
        onChange={event => {

          event.preventDefault();
          setValue(event.target.value);
        }}

      />
    </div>

  );
};
export default CustomInputTextGenero;