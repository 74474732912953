/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

export default props => {

  return (
    <Menu {...props}>

      <Link
        id="menu-1"
        className="menu-item"
        to="/"
      >
        Inicio
      </Link>

      <Link
        id="menu-2"
        className="menu-item"
        to="/encuesta"
      >
        La encuesta
      </Link>

      <Link
        id="menu-3"
        className="menu-item"
        to="/mas_informacion"
      >
        Más información
      </Link>

      <Link
        id="menu-4"
        className="menu-item"
        to="/sobre_esf"
      >
        Sobre Economistas sin fronteras
      </Link>

      <Link
        id="menu-5"
        className="menu-item"
        to="/aviso_legal"
      >
        Aviso legal
      </Link>
    </Menu>
  );
};
