import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const ModalGenero = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <p>
          El uso del tiempo está muy vinculado a los roles de género.
        </p>
        <button
          data-dismiss="modal" aria-label="Close"
          className="modal-close-button"
          onClick={hide}
        >
          <div className="div-button-modal" >Cerrar</div>
        </button>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default ModalGenero;