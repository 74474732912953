import React, { useEffect } from "react";
import CustomInputTextEmail from "./CustomInputTextEmail";

function InputTextEmail() {

  const ls_value = localStorage.getItem("email");

  const [value, setValue] = React.useState(ls_value == null ? '' : ls_value);

  useEffect(() => {
    localStorage.setItem("email", value);
  }, [value]);

  return <CustomInputTextEmail label="Input_email" value={value} setValue={setValue} />;
}

export default InputTextEmail;
