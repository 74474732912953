import React from "react";
import Highcharts from "highcharts/highstock";
import BarChart from "highcharts-react-official";

function Grafica2() {

  const options = {
    colors: ["#C66FC6", "#62B997"],
    chart: {
      height: '600px',
      type: 'bar',
      backgroundColor: '#fcfceb'
    },
    title: {
      align: "center",
      floating: false,
      margin: 30,
      style: {
        "color": "#0C1547",
        fontSize: "36px",
        fontWeight: 900,
        "text-align": "center",
      },
      text: 'Desigualdades de género en el uso del tiempo'
    },
    subtitle: {
      margin: 30,
      style: {
        "color": "#0C1547",
        fontSize: "16px",
        fontWeight: 700,
        "text-align": "center",
      },
      text: 'Fuente: elaboración propia a partir de la última Encuesta de Empleo del Tiempo (INE) para la ciudad de Madrid'
    },
    xAxis: {
      categories: ['Autocuidado', 'Empleo', 'Estudios', 'Trabajo doméstico', 'Activismo', 'Ocio', 'Trayectos'],
      title: {
        text: null
      },
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      step: 1,
      min: 0,
      max: 12,
      title: {
        text: 'Horas (promedio diario)',
        align: 'middle'
      },
      labels: {
        overflow: 'justify',

      }
    },
    tooltip: {
      valueSuffix: ' horas'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      y: 100,
      floating: true,
      borderWidth: 0,
      backgroundColor: '#fcfceb',
      shadow: false
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Mujeres',
      data: [11.35, 6.51, 4.30, 4.15, 2.01, 7.56, 1.30]
    }, {
      name: 'Hombres',
      data: [11.36, 7.42, 4.32, 2.25, 1.48, 9.13, 1.36]
    }]
  };

  return (
    <React.Fragment>

      <div className="highchart-box">

        <div>
          <BarChart highcharts={Highcharts} options={options} />
        </div>
        <p className="grafica-parrafo">
          Las mujeres dedican más tiempo que los hombres al trabajo doméstico y los cuidados. Según el INE, en Madrid las mujeres dedican un promedio de 29 h 45 min a la semana, los hombres 16 h 55 min.
        </p>
        <p className="grafica-parrafo">
          Esto supone que cuentan con menos tiempo para otras actividades y explica gran parte de las desigualdades de género en el mercado laboral.
        </p>
      </div>

    </React.Fragment>
  );
}

export default Grafica2;
